/*------------------------------------------------------------------------
 # author    Eric Worman, HotRocket Development, Inc.
 # copyright Copyright  2014. All rights reserved.
 # @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
 # Website   eTraining LMS
 # Path: /resources/assets/js/admin.js
 -------------------------------------------------------------------------*/

function deleteRecord(url, confirm, message)
{
    //If the deletion needs to be confirmed, display panel, bind event to the delete button and return
    if(confirm == true)
    {
        $("#confirmDeleteBtn").unbind();
        $('#confirmDeleteBtn').bind('click', function(){
           deleteRecord(url, false, message);
        });

        //If the message is not null set message
        if(message != null) { $('#deleteMessage').html(message); }

        //Show the modal window and return
        $('#confirmDeletePanel').modal('show');

        return;
    }

    //Load the URL
    window.location.href=url;
}


function selectLearner(elm)
{
    //Determine if this is selected or not
    var selected = $(elm).hasClass('selected');
    var learnerIDs;

    //Deselect if selected
    if (selected == true)
    {
        $(elm).removeClass('selected');
    }
    else
    {
        $(elm).addClass('selected');
    }

    $('#groupLearners').val('');

    //Group through all selected learners and build a string of IDs
    $('.learner').each(function(){

        if($(this).hasClass('selected'))
        {
            //Grab the current IDs from the group learners hidden field
            learnerIDs = $('#groupLearners').val();

            //Append the current learner ID to the string
            if (learnerIDs != '') { learnerIDs = learnerIDs + ":" + $(this).attr("data-id"); } else { learnerIDs = $(this).attr('data-id'); }

            //Update the hidden field
            $('#groupLearners').val(learnerIDs);


        }
    });
}

function checkClientLicense(clientID)
{
    var url = 'account/check-license/' + clientID;

    $.get(url, function(response){

        //If the client is out of licenses, then disable the save button and display warning alert
        if(response == 'invalid')
        {
            $('#licenseExceeded').removeClass('hide');
            $('#invalidLicense').removeClass('hide');
            $('#licenseExpired').addClass('hide');
            $('#expiredLicense').addClass('hide');
            $('.saveLearner').addClass('hide');
        }
        else if (response == 'expired')
        {
            $('#licenseExpired').removeClass('hide');
            $('#expiredLicense').removeClass('hide');
            $('.saveLearner').addClass('hide');
        }
        else if (response == 'valid')
        {
            $('#licenseExceeded').addClass('hide');
            $('#invalidLicense').addClass('hide');
            $('#licenseExpired').addClass('hide');
            $('#expiredLicense').addClass('hide');
            $('.saveLearner').removeClass('hide');
        }

    });
}


function updateGroupLearners()
{
    //Init func vars
    var curID;

    //Retrieve the learners from the group learners field
    var curLearners = $('#groupLearners').val();
    curLearners = curLearners.split(':');

    //Update the learners
    if (curLearners.length > 0)
    {
        //Loop through each learner
        $('.learner').each(function(){
            curID = $(this).attr('data-id');

            for(a=0; a<curLearners.length; a++)
            {
                if(curLearners[a] == curID) { $(this).addClass('selected'); }
            }
        });

    }
}

function updateGroupAdmins(groupAdminID)
{
    //Retrieve group admins based on client id
    var clientID = $("#client_id").val();

    //Load the learners for this client
    var url = "groups/group-admins/" + clientID;

    $.get(url, function(groupAdmins){
        if(groupAdmins[0] == null)
        {
            $('#groupAdminId select').html('<option value="0">No Records Found</option>').selectpicker('refresh');
        }
        else
        {
            $('#groupAdminId select').html('');

            for(a=0; a<groupAdmins.length; a++)
            {
               //If the group admin id matches the current id, set the selected attribute
                if(groupAdmins[a].id == groupAdminID)
                {
                    $('#groupAdminId select').append('<option value="' + groupAdmins[a].id + '" selected>' + groupAdmins[a].name + '</option>');
                }
                else
                {
                    $('#groupAdminId select').append('<option value="' + groupAdmins[a].id + '">' + groupAdmins[a].name + '</option>');
                }
            }

            $('#groupAdminId select').selectpicker('refresh');
        }
    });
}


function loadGroupAdmins(curForm, curGroupAdmins)
{
    //Retrieve groups based on client id
    var clientID = $(curForm + " #client_id").val();
    var selected = null;

    //If there's no client id log error and return
    if(clientID == undefined) { console.log('Invalid Client ID, unable to load group admins.'); return; }

    //Load the learners for this client
    var url = "groups/load-group-admins/" + clientID;

    $.get(url, function(groupAdmins){

        if(groupAdmins[0] == null)
        {
            $(curForm + ' #groupAdminIdGroup select').html('<option value="0">No Records Found</option>').selectpicker('refresh');
        }
        else
        {
            $(curForm + ' #groupAdminIdGroup select').html('').selectpicker('refresh');

            for(a=0; a<groupAdmins.length; a++)
            {
                if(curGroupAdmins != undefined)
                {
                    //If the group admin id matches the current id, set the selected attribute
                    for(b=0; b<curGroupAdmins.length; b++)
                    {
                        //If the user is currently assigned to the group, set the selected attribute
                        if(groupAdmins[a].id == curGroupAdmins[b].id) {  selected = 'selected';  }
                    }

                    $(curForm + ' #groupAdminIdGroup select').append('<option value="' + groupAdmins[a].id + '" ' + selected + '>' + groupAdmins[a].name + '</option>');

                    //Re-init selected to null to prevent selecting every option
                    selected = null;
                }
                else
                {
                    //Simply populate the select menu
                    $(curForm + ' #groupAdminIdGroup select').append('<option value="' + groupAdmins[a].id + '">' + groupAdmins[a].name + '</option>');
                }
            }

            $(curForm + ' #groupAdminIdGroup select').selectpicker('refresh');
        }
    });
}


function updateCourseMenu(courseIDs)
{
    //Retrieve currently selected series filter
    var filter = $("#courseSeriesFilter").val();

    //Retrieve the courses for this client that are part of the series
    var url = '/course-library/client-courses/' + filter;

    $.get(url, function(response){

        var a, b, html, selected;
        var cids = $.parseJSON(courseIDs);

        //Clear out the existing select menu options
        $("#availableCourses").html('');

        //Loop through the response and rebuild the course menu
        for(a=0; a<response.length; a++)
        {
            selected = '';

            //Loop through the course ids and check if this course is already assigned, if so check and disable it
            for(b=0; b<cids.length; b++)
            {
                if(cids[b] == response[a].id) { selected = 'selected disabled'; }
            }

            //If this is an inactive course, make it disabled
            if(response[a].active == '0')
            {
                selected = ' data-icon="glyphicon glyphicon-ban-circle" data-subtext="Inactive" class="courseInactive" disabled ';
            }

            html = '<option value="' + response[a].id + '" ' + selected  + '>' + response[a].title + '</option>';
            $("#availableCourses").append(html);
        }

        $('#availableCourses').selectpicker('refresh');

    });
}


function assignLearnerCourses(userID)
{
    //Retrieve the course ids from the course dropdown menu
    var selectedCourses = $('#availableCourses').val();

    //Confirm the alert panels are hidden
    $('#courseMessage').addClass('hide');
    $('#courseWarning').addClass('hide');

    $.ajax({
        type: "POST",
        url: "learners/assign-courses",
        data: { course_ids: selectedCourses, user_id: userID, _token: $("input[name=_token]").val() },

        success: function() {

            $('#courseMessage span').html('Course assignment successful.');
            $('#courseMessage').removeClass('hide');

            setTimeout(function(){ $('.alert').addClass('hide'); }, 10000);

        },
        error: function(){

            $('#courseWarning span').html('Course assignment failed, please notify system administrator.');
            $('#courseWarning').removeClass('hide');

			setTimeout(function(){ $('.alert').addClass('hide'); }, 10000);

		}
    });
}

function unassignObject(objectType, assignmentID, objectID, confirm)
{
    var entity;

    if(confirm == true)
    {
        $('#confirmUnassignObjectPanel').modal();

        $('#objectType').html(objectType);

        $('#unassignObjectBtn').on('click', function(){
            unassignObject(objectType, assignmentID, objectID, false);
        });

        return;
    }

    //Grab the entity id
    var entityID = $('#id').val();
    var entity = $('#entity').val();

    //Build URL to perform course unassignment
    $.ajax({
        type: "POST",
        url: "object-assignments/unassign",
        data: {
            object_type: objectType,
            object_id: objectID,
            assignment_id: assignmentID,
            entity_id: entityID,
            entity: entity,
            action: 'remove',
            _token: $("input[name=_token]").val() },

        success: function() {

            $('#objectSuccess span').html('The ' + objectType + ' assignment removed.');
            $('#objectSuccess').removeClass('hide');

            //Based on the type of object, remove the appropriate item from the page
            switch(objectType)
            {
                case "quiz":
                    $('#quizID-' + objectID).fadeOut(200);
                    break;
                case "course":
                    $('#courseID-' + objectID).fadeOut(200);
                    break;
                case "resource":
                    $('#resourceID-' + objectID).fadeOut(200);
                    break;
            }

        },
        error: function(){

            $('#objectWarning span').html('<strong>ERROR!</strong> The ' + objectType + ' assignment removal failed, please notify system administrator.');
            $('#objectWarning').removeClass('hide');
        }
    });
}



/* Support Functions --------------------------------------------------------------------------------------------------------- */



/* Import Learners --------------------------------------------------------------------------------------------------------- */

function previewImport()
{
    //Hide any previous errors
    $('.clientAlertWarning').css('display', 'none');

    //Confirm both previous and current clients have been selected
    var prevClient = $('#previous_clients').val();
    var curClient = $('#current_client').val();
    
    if(prevClient == '0') { $('#clientWarningText').text('Invalid source client.'); $('.clientAlertWarning').css('display', 'block');  return; }
    if(curClient == '0') { $('#clientWarningText').text('Invalid target client.'); $('.clientAlertWarning').css('display', 'block'); return; }

    //Retrieve previous learners
    var url = 'import/preview/' + prevClient;

    //Update preview button to prevent multiple clicks
    $('#previewImportButton').prop('disabled', true).addClass('disabled').html('<span class="fa fa-spinner fa-pulse"></span> Retrieving Learners');

    $.get(url, function(response){

        //Reset the preview import button
        $('#previewImportButton').prop('disabled', false).removeClass('disabled').html('Preview Import');

        //Load the html response into the preview import panel
        $('#previewImportPanel').html(response);
    });
}

function importLearners(confirm)
{
    //If confirm is false, display the modal window confirming the import should take place
    if(confirm == false)
    {
        $('#confirmImportPanel').modal();
        return;
    }

    //Close the import panel
    $('#confirmImportPanel').modal('hide');
    
    //Confirm both previous and current clients have been selected
    var prevClient = $('#previous_clients').val();
    var curClient = $('#current_client').val();

    if(prevClient == '0') { $('#clientWarningText').text('Invalid source client.'); $('.clientAlertWarning').css('display', 'block');  return; }
    if(curClient == '0') { $('#clientWarningText').text('Invalid target client.'); $('.clientAlertWarning').css('display', 'block'); return; }

    //Disable preview button
    $('#previewImportButton').prop('disabled', true).addClass('disabled');
    $('.importLearner').prop('disabled', true).addClass('disabled').html('<span class="fa fa-spinner fa-pulse"></span> Importing Learners');

    $.ajax({
        type: "POST",
        url: "import/import-learners",
        data: { prev_client: prevClient, cur_client:curClient, _token: $("input[name=_token]").val() },

        success: function(data) {

            //Display alert panel indicating import successful
            var html = ' <div class="alert alert-success">';
            html += '<a href="#" class="close" data-dismiss="alert">&times;</a>';
            html += '<strong>IMPORT SUCCESSFUL</strong> <span id="clientWarningText">' + data.msg + '</span>';
            html += '</div>';

            $('#previewImportPanel').html(html);

            $('#previewImportButton').prop('disabled', false).removeClass('disabled');
            $('.importLearner').prop('disabled', false).removeClass('disabled').html('Import Learners');

        },
        error: function(data){

            //Display alert panel indicating import successful
            var html = ' <div class="alert alert-warning">';
            html += '<a href="#" class="close" data-dismiss="alert">&times;</a>';
            html += '<strong>ERROR!</strong> <span id="clientWarningText">' + data.errors + '</span>';
            html += '</div>';

            $('#previewImportPanel').html(html);
        }
    });

}


/* Quizzes --------------------------------------------------------------------------------------------------------- */

function manageQuestion(action)
{
    //Init func vars
    var msg;

    //Clear alerts and field errors
    $('.questionAlert').css('display', 'none');
    $('#questionContainer .form-group').removeClass('fieldError');

    if(action == 'confirmRemove')
    {
        $('#confirmDeleteQuestionPanel').modal();
        return;
    }

    //Set the action input field
    $('#questionAction').val(action);

    //Retrieve question data
    var data = $('#questionContainer input').serialize();

    $.ajax({
        type: "POST",
        url: "quizzes/save-question",
        data: data,
        success: function() {

            //Clear question field
            $('.clearValue').val('');

            //Reload the current questions dropdown menu
            loadQuestions();

            //Hide the save button, show the edit/delete buttons
            $(".addButtons").css('display', 'inline');
            $('.editButtons').css('display', 'none');

            if(action == 'add') {  msg = 'Question has been successfully added.'; } else { msg = 'Question has been successfully updated.'; }

            //Set the question warning text and display warning alert
            $('#questionAlertSuccess span').html(msg);
            $("#questionAlertSuccess").css('display', 'block');

            setTimeout(function(){ $("#questionAlertSuccess").fadeOut(250); }, 3000);

        },
        error: function(data){

            var errorString = '';
            var errors = data.responseJSON.errors;

            for (var key in errors) {
                if (errors.hasOwnProperty(key)) {
                    errorString += errors[key] + " ";
                    $('#' + key + 'Group').addClass('fieldError');
                }
            }

            //Set the question warning text and display warning alert
            $('#questionAlertWarning span').html(errorString);
            $("#questionAlertWarning").css('display', 'block');
        }
    });
}

function loadQuestions()
{
    //Grab current quiz id
    var quizID = $('#quizID').val();

    var url = 'quizzes/load-questions/' + quizID;
    $.get(url, function(response){


        if(response.length == 0)
        {
            //Load questions into current questions dropdown
            $('#cur_questions').html('<option value="0">No questions defined.</option>');
        }
        else
        {
            //Load questions into current questions dropdown
            $('#cur_questions').html('<option value="0">--Select Question--</option>');

            for(a=0; a<response.length; a++)
            {
                $('#cur_questions').append('<option value="' + response[a].id + '">' + response[a].question + '</option>');
            }
        }



        //Refresh selectpicker
        $('#curQuestionsGroup select').selectpicker('refresh');
    });
}

function loadQuestionDetails(questionID)
{
    //If question id is 0, cancel the manage question request
    if(questionID == 0) { cancelManageQuestion(); return; }

    var url = 'quizzes/question-details/' + questionID;

    $.get(url, function(response){

        //Populate form vars
        for (var key in response) {
            if (response.hasOwnProperty(key)) {

                $('#' + key).val(response[key]);
            }
        }

        //Set the question ID value
        $('#questionID').val(questionID);

        //Hide the save button, show the edit/delete buttons
        $(".addButtons").css('display', 'none');
        $('.editButtons').css('display', 'inline');

    });
}

function cancelManageQuestion()
{
    $('#questionContainer .form-group input').val('');
    $('#questionID').val('');
    $('#cur_questions').val(0);
    $('#curQuestionsGroup select').selectpicker('refresh');

    //Hide the save button, show the edit/delete buttons
    $(".addButtons").css('display', 'inline');
    $('.editButtons').css('display', 'none');

    $("#questionAlertWarning").css('display', 'none');

}


/* Reporting --------------------------------------------------------------------------------------------------------- */

function exportReport(type)
{
	//Init func vars
	var formData;

	//Switch type of report to retrieve necessary details
	switch(type)
	{
		case "download-tracking":
			formData = {
				_token: $('input[name="_token"]').val(),
				resource_id: $('#resourceID').val(),
				group_id: $('#groupID').val(),
				report_type: 'downloads',
				draw: 1
			};
			break;

		case "course-stats":
			formData = {
				_token: $('input[name="_token"]').val(),
				course_id: $('#courseID').val(),
				group_id: $('#groupID').val(),
				client_id: $('#clientID').val(),
				report_type: 'course-stats',
				search: { value: $('#courseStatsTable_filter input').val(), regex: false },
				draw: 1
			};
			break;

		case "incomplete-courses":
			formData = {
				_token: $('input[name="_token"]').val(),
				course_id: $('#courseID').val(),
				group_id: $('#groupID').val(),
				client_id: $('#clientID').val(),
				report_type: 'incomplete-courses',
				search: { value: $('#incompleteCoursesTable_filter input').val(), regex: false },
				draw: 1
			};
			break;
	}

	//Download details
	//$('#downloadTarget').attr('src', );
	//$('.downloadLink').html('Exporting <span class="fa fa-cog fa-spin"></span>');
	window.open('/reports/download/?report_type='+ type +'&request=' + encodeURIComponent(JSON.stringify(formData)));
}

function reportReminder(report, userID)
{

}


function loginTrackingFilter(groupId, loadPage)
{
    //Hide any visible checkmarks
    $('.formHeader .glyphicon-ok').css('visibility', 'hidden');

    //Retrieve the group name for the download button
    var groupElm = '#group-' + groupId;
    var groupName = $(groupElm).attr('data-groupname');

    //Set the button text and display the checkbox in the menu
    $('#selectGroupDisplay').html(groupName);
    $(groupElm + ' .glyphicon-ok').css('visibility', 'visible');

    //If we need to actually load the page, do so otherwise just update the menu. Used on page load to set the menu
    if(loadPage == true)
    {
        //Reload the page with the group id added
        if(groupId == 'null')
        {
            window.location.href = '/reports/login-tracking';
        }
        else
        {
            window.location.href = '/reports/login-tracking?group_id=' + groupId;
        }
    }
}


function reportFilter(id, filterObject, loadPage, url)
{
    //Hide any visible checkmarks
    $('.formHeader .glyphicon-ok').css('visibility', 'hidden');

    //Retrieve the group name for the download button
    var elm = '#' + filterObject + '-' + id;
    var name = $(elm).attr('data-name');

    //Set the button text and display the checkbox in the menu
    $('#' + filterObject + '-display').html(name);
    $(elm + ' .glyphicon-ok').css('visibility', 'visible');

    //If we need to actually load the page, do so otherwise just update the menu. Used on page load to set the menu
    if(loadPage == true)
    {
        window.location.href = url;
    }
}

function downloadTrackingFilter(groupId, loadPage)
{
    //Hide any visible checkmarks
    $('.formHeader .glyphicon-ok').css('visibility', 'hidden');

    //Retrieve the group name for the download button
    var groupElm = '#group-' + groupId;
    var groupName = $(groupElm).attr('data-groupname');

    //Set the button text and display the checkbox in the menu
    $('#selectGroupDisplay').html(groupName);
    $(groupElm + ' .glyphicon-ok').css('visibility', 'visible');

    //If we need to actually load the page, do so otherwise just update the menu. Used on page load to set the menu
    if(loadPage == true)
    {
        //Reload the page with the group id added
        if(groupId == 'null')
        {
            window.location.href = '/reports/download-tracking';
        }
        else
        {
            window.location.href = '/reports/download-tracking/' + groupId;
        }
    }
}

/*
function searchLearners(table, learnerKeyword)
{
    //Build search url based on keyword
    url = '/reports/course-stats/' + '?k=' + learnerKeyword + '&t=' + table;

    window.location.href = url;

    return;
}
*/



function exceedWarningFilter(quizId, loadPage)
{
    //Retrieve the group name for the download button
    var quizElm = '#quiz-' + quizId;
    var quizName = $(quizElm).attr('data-quizname');

    //Set the button text and display the checkbox in the menu
    $('#selectQuizDisplay').html(quizName);

    //If we need to actually load the page, do so otherwise just update the menu. Used on page load to set the menu
    if(loadPage == true)
    {
        //Reload the page with the group id added
        if(quizId == 'null')
        {
            window.location.href = '/reports/exceed-warning';
        }
        else
        {
            window.location.href = '/reports/exceed-warning/' + quizId;
        }
    }
}

function assignedCoursesFilter(groupAdminId, loadPage)
{
    //Retrieve the group name for the download button
    var groupAdminElm = '#group_admin-' + groupAdminId;
    var groupAdminName = $(groupAdminElm).attr('data-groupadmin');

    //Set the button text and display the checkbox in the menu
    $('#selectGroupAdminDisplay').html(groupAdminName);

    //If we need to actually load the page, do so otherwise just update the menu. Used on page load to set the menu
    if(loadPage == true)
    {
        //Reload the page with the group id added
        if(groupAdminId == 'null')
        {
            window.location.href = '/reports/assigned-courses';
        }
        else
        {
            window.location.href = '/reports/assigned-courses/' + groupAdminId;
        }
    }
}

function completedQuizzesFilter(quizId, loadPage)
{
    //Retrieve the group name for the download button
    var quizElm = '#quiz-' + quizId;
    var quizName = $(quizElm).attr('data-quizname');

    //Set the button text and display the checkbox in the menu
    $('#selectQuizDisplay').html(quizName);

    //If we need to actually load the page, do so otherwise just update the menu. Used on page load to set the menu
    if(loadPage == true)
    {
        //Reload the page with the group id added
        if(quizId == 'null')
        {
            window.location.href = '/reports/quiz-results';
        }
        else
        {
            window.location.href = '/reports/quiz-results/' + quizId;
        }
    }
}

function sendNotification(type, object, id)
{
    //Remove any alerts
	$('.alert').remove();

	var data = {
        object: object,
		type: type,
        id: id,
        _token: $("input[name=_token]").val()
    };

    $.ajax({
        type: "POST",
        url: "notifications/" + type,
        data: data,
        success: function(response) {

        	$('.reportTableWrapper').append('<div class="alert alert-success text-left">' + response.message + '</div>');

        },
        error: function(response){

			$('.reportTableWrapper').append('<div class="alert alert-danger text-left">' + response.message + '</div>');

        }
    });
}

function remindAllUsers(reportElm)
{
	$('.alert').remove();

	$.ajax({
		type: "POST",
		url: "notifications/remind-all",
		data: $(reportElm).serialize(),
		success: function(response) {

			$('.reportTableWrapper').append('<div class="alert alert-success text-left">' + response.message + '</div>');

		},
		error: function(response){

			console.log(response);

			$('.reportTableWrapper').append('<div class="alert alert-danger text-left">' + response.message + '</div>');

		}
	});
}


function generateAPIKey(targetElm)
{
    //Create url to grab new api key
    var url = '/client-accounts/create-key';

    $.get(url, function(key){
       $(targetElm).val(key);
    });
}



function loadLearners(type)
{
    //Grab the current id based on type of learners to load
    switch(type)
    {
        case "client":
            var id = $("#client_id").val();
            break;
        case "groupAdmin":
            var id = $("#group_admin_id").val();
            break;
        default:
            var id = null;
            break;
    }

    //Load the learners for this client
    var url = "groups/load-learners/" + type + "/" + id;

    $.get(url, function(html){
        $('#learnersPanel').html(html);

        //Update the group learners
        updateGroupLearners();
    })
}


/* Course Series --------------------------------------------------------------------------------------------------------- */

function displayCourseSeries(curSeries)
{

    //Remove the no courses via filter message
    $('.noCoursesViaFilter').remove();

    //Hide all courses
    $('.coursesContainer li').addClass('hideCourse');

    //Get total number of courses
    var totalCourses = $('.coursesContainer li').length;

    //Show the series based on the current series selected
    if(curSeries == 'all')
    {
        $('.coursesContainer li').removeClass('hideCourse');
    }
    else if (curSeries == '0')
    {
        $('.coursesContainer li.series-').removeClass('hideCourse');
        $('.coursesContainer li.series-0').removeClass('hideCourse');
    }
    else
    {
        $('.coursesContainer li.series-' + curSeries).removeClass('hideCourse');
    }

    //Grab the total number of courses with the hideCourse class applied
    var totalHidden = $('.coursesContainer li.hideCourse').length;

    if(totalCourses == totalHidden)
    {
        //If all the courses are hidden, display the 'No courses found' box
        $('.coursesContainer').append('<li class="noCoursesFound noCoursesViaFilter">No course found for the selected series.</li>')
    }
}


/* Assignment Functions --------------------------------------------------------------------------------------------------------- */


function initAssignmentBlock(curLearners, curGroups, curClients, objectType)
{
    //Setup Selectpicker Dropdown Menus
    $('#assignmentTypeGroup select').selectpicker();
    $('#clientIdGroup select').selectpicker({ liveSearch: true });
    $('#groupIdGroup select').selectpicker({ liveSearch: true });
    $('#learnersGroup select').selectpicker({ liveSearch: true });

    //Insert a "select" option into the group admin dropdown menu
    var curOptions = $('#groupAdminGroup select').html();
    $('#groupAdminGroup select').html('<option value="0">--Select Group Admin--</option>');
    $('#groupAdminGroup select').append(curOptions);
    $('#groupAdminGroup select').selectpicker({ liveSearch: true });


    if(curLearners == '' || curLearners == undefined) { curLearners = null; }
    if(curGroups == '' || curGroups == undefined) { curGroups = null; }

    //Add onchange listener to assignment type dropdown menu
    $('#assignmentTypeGroup select').on('change', function(){

        displayAssigmentTypes();
    });

    //Display initial assignment types
    displayAssigmentTypes();

    //Add onchange listener to client dropdown menu
    $('#clientIdGroup select').on('change', function(){

        loadLearnersMenu('#client_id', '#group_id', '#learners', curLearners);
        loadGroups('#client_id', '#group_id', curGroups);
    });

    //Add listener to update learners when a specific group is selected
    $('#groupIdGroup select').on('change', function(){

        loadLearnersMenu('#client_id', '#group_id', '#learners', curLearners);
    });

    //Load the groups and learners on initial page load
    setClientIDs('#client_id', curClients);
    loadGroups('#client_id', '#group_id', curGroups);
    loadLearnersMenu('#client_id', '#group_id', '#learners', curLearners);

    //Add listeners to update object assignments
    $('#groupIdGroup select').on('changed.bs.select', function(e, clickedIndex, newValue){

        var groupID = $('#groupIdGroup option').eq(clickedIndex).val();
        var resourceID = $('#id').val();
        var assignType = $('#assignment_type').val();

        if(assignType == 'group_id')
        {
            //updateObjectAssignment('group_id', objectType, groupID, resourceID, newValue);
        }

    });

    $('#learnersGroup select').on('changed.bs.select', function(e, clickedIndex, newValue){

        var userID = $('#learnersGroup option').eq(clickedIndex).val();
        var resourceID = $('#id').val();
        var assignType = $('#assignment_type').val();

        if(assignType == 'user_id')
        {
            //updateObjectAssignment('user_id', objectType, userID, resourceID, newValue);
        }

    });

    //Setup listener on client select menu to display warning when client id is changed
    $("#clientIdGroup select").on('changed.bs.select', function(){
       $('#clientChangeWarning').removeClass('hide');
    });
}


function loadGroups(clientElm, groupElm, groupID)
{
    //Retrieve groups based on client id
    var clientID = $(clientElm).val();
    var selected = null;
    var disabled = null;

    //Convert groupID to json
    if(groupID != null && groupID != undefined && groupID != '') { groupID = $.parseJSON(groupID); }

    //If there's no client id log error and return
    if(clientID == undefined) { return; }

    //Load the learners for this client
    var url = "groups/load-groups/" + clientID;

    $.get(url, function(groups){

        if(groups[0] == null)
        {
            //Set the select menu HTML
            $(groupElm).html('<option value="0">No Records Found</option>');

            //Since selectpicker is initiated using the selectpicker class, refresh it using the same
            $(groupElm).selectpicker('refresh');
        }
        else
        {
            //Wipe out the existing select options
            $(groupElm).html('');

            //Since selectpicker is initiated using the selectpicker class, refresh it using the same
            $(groupElm).selectpicker('refresh');

            for(a=0; a<groups.length; a++)
            {

                if(groupID != undefined)
                {
                    //If the group admin id matches the current id, set the selected attribute
                    for(b=0; b<groupID.length; b++)
                    {
                        //If the user is currently assigned to the group, set the selected attribute
                        if(groups[a].id == groupID[b]) {  selected = 'selected';  }
                    }

                    if(groups[a].protected == '1') { disabled = 'disabled'; } else { disabled = ''; }

                    $(groupElm).append('<option value="' + groups[a].id + '" ' + selected + ' ' + disabled + '>' + groups[a].name + '</option>');

                    //Re-init selected to null to prevent selecting every option
                    selected = null;
                }
                else
                {
                    //Simply populate the select menu
                    $(groupElm).append('<option value="' + groups[a].id + '">' + groups[a].name + '</option>');
                }
            }

            //Since selectpicker is initiated using the selectpicker class, refresh it using the same
            $(groupElm).selectpicker('refresh');
        }
    });
}


function loadLearnersMenu(clientElm, groupElm, learnerElm, curLearners)
{
    //Retrieve groups based on client id
    var clientID = $(clientElm).val();
    var groupIDs = $(groupElm).val();
    //groupIDs = groupIDs.join(':');

    var selected = null;

    //If there is no client id or group id, log error and return
    if(clientID == undefined && groupIDs == undefined) { return; }

    //Load the learners for this client
    var url = "learners/load-learners/" + clientID + "/" + groupIDs;

    //Convert the curLearners var to json
    if(curLearners != undefined && curLearners != null && curLearners != '') { curLearners = $.parseJSON(curLearners); }

    $.get(url, function(learners){

        if(learners[0] == null)
        {
            $(learnerElm).html('<option value="0">No Records Found</option>');

            //Since selectpicker is initiated using the selectpicker class, refresh it using the same
            $(learnerElm).selectpicker('refresh');
        }
        else
        {
            $(learnerElm).html('');

            //Since selectpicker is initiated using the selectpicker class, refresh it using the same
            $(learnerElm).selectpicker('refresh');

            for(a=0; a<learners.length; a++)
            {
                if(curLearners != undefined)
                {
                    //If the group admin id matches the current id, set the selected attribute
                    for(b=0; b<curLearners.length; b++)
                    {
                        //If the user is currently assigned to the group, set the selected attribute
                        if(learners[a].id == curLearners[b]) {  selected = 'selected';  }
                    }

                    $(learnerElm).append('<option value="' + learners[a].id + '" ' + selected + '>' + learners[a].name + '</option>');

                    //Re-init selected to null to prevent selecting every option
                    selected = null;
                }
                else
                {
                    //Simply populate the select menu
                    $(learnerElm).append('<option value="' + learners[a].id + '">' + learners[a].name + '</option>');
                }
            }

            //Since selectpicker is initiated using the selectpicker class, refresh it using the same
            $(learnerElm).selectpicker('refresh');
        }
    });
}

function setClientIDs(clientElm, curClients)
{
    //If there are no clients, simply return
    if(curClients == '' || curClients == undefined) { return; }

    //Parse the curClients into json
    curClients = $.parseJSON(curClients);

    //Deselect all currently selected options
    $(clientElm + ' option:selected').attr('selected', false);

    for(a=0; a<curClients.length; a++)
    {
        //Loop through each select value
        $(clientElm + ' option').each(function(){
           if($(this).val() == curClients[a])
           {
               $(this).attr('selected', true);
           }
        });
    }

    $('.selectpicker').selectpicker('refresh');

}


function displayAssigmentTypes()
{
    var curTarget = $('#assignmentTypeGroup select').val();

    $('.resourceTarget').css('display', 'none');

    //Display the selection dropdown menu based on assignment type
    switch(curTarget)
    {
        case "client_id":
            $('#clientIdGroup').css('display', 'block');
            break;
        case "group_id":
            $('#clientIdGroup').css('display', 'block');
            $('#groupIdGroup').css('display', 'block');
            break;
        case "user_id":
            $('#clientIdGroup').css('display', 'block');
            $('#groupIdGroup').css('display', 'block');
            $('#learnersGroup').css('display', 'block');
            break;
    }

    //Reformat the form labels
    formatFieldLabels();
}

function selectAssignedCourses()
{
    //Retrieve currently assigned course ids
    var curIDs = $('#courseIDs').val();

    var curIDs = curIDs.split(',');

    for(a=0; a<curIDs.length; a++)
    {
        //Loop through each course and select it if it was previously set
        $('#recordList li').each(function(){
           if($(this).attr('data-id') == curIDs[a])
           {
               $(this).addClass('selected');
           }
        });
    }
}

function assignClientCourses(curElm)
{
    //If a current element has been passed, toggle the selected attribute
    if(curElm != null)
    {
        //Determine if this class is already selected or not
        if($(curElm).hasClass('selected'))
        {
            $(curElm).removeClass('selected');

            //Remove the previously selected course assignment
            $("#curCourseAssignments").html('<p class="noRecordsFound">No course selected.</p>');
        }
        else
        {
            $(curElm).addClass('selected');

            //Retrieve the course assignments for the current client and update course assignments block
            //updateCourseAssignments($(curElm).attr('data-id'));
        }
    }


    //Clear out the list of currently selected courses and re-populate
    $('#courseIDs').val('');

    var selectedCourses = $("#recordList li.selected");
    var courseIDs = '';

    //Loop through the selected courses and rebuild the courseIDs string
    for(a=0; a<selectedCourses.length; a++)
    {
        if(a == 0)
        {
            courseIDs = $(selectedCourses[a]).attr('data-id');
        }
        else
        {
            courseIDs += ',' + $(selectedCourses[a]).attr('data-id');
        }
    }

    $('#courseIDs').val(courseIDs);
}

/*
function updateObjectAssignment(entity, objectType, entityID, objectID, newVal)
{
    //Init func vars
    var action;

    //Based on cur/pre values either add or remove the object assignment
    if(newVal == true) { action = 'add'; } else { action = 'remove'; }

    //Build URL based on entity
    switch(objectType)
    {
        case "quiz":
            url = "quizzes/update-assignment";
            break;
        case "resource":
            url = "resources/update-assignment";
            break;
        case "course":
            url = "course-library/update-assignment";
            break;
        default:
            console.log('Invalid entity');
            return false;
    }

    $.ajax({
        type: "POST",
        url: url,
        data: { entity: entity, entity_id:entityID, object_type:objectType, object_id: objectID, action: action, _token: $("input[name=_token]").val() },
        success: function(response) {

        },
        error: function(){

        }
    });
}
*/

/*
function updateCourseAssignments(courseID)
{
    var html = '';

    //Retrieve the course assignments from the object assignments table
    var url = '/course-library/assignments/' + courseID;

    $.get(url, function(response){

        var r = response;

        var groups = r.groups;
        var learners = r.learners;

        //Add the groups first
        for(a=0; a<groups.length; a++)
        {
            html += '<a id="assignment-' + groups[a].id + '" data-courseid="' + courseID + '" data-id="' + groups[a].group_id + '" data-type="group_id" data-selected="true" href="javascript:" class="courseAssignment groupAssignment" onClick="toggleAssignment(\'' + groups[a].id + '\');">';
            html += '<span class="fa fa-users"></span> ' + groups[a].name + '<span class="pull-right fa fa-check success"></span>';
            html += '</a>';
        }

        //Add the learners
        for(a=0; a<learners.length; a++)
        {
            html += '<a id="assignment-' + learners[a].id + '" data-courseid="' + courseID + '" data-id="' + learners[a].user_id + '" data-type="user_id" data-selected="true" href="javascript:" class="courseAssignment learnerAssignment" onClick="toggleAssignment(\'' + learners[a].id + '\');">';
            html += '<span class="fa fa-user"></span> ' + learners[a].name + '<span class="pull-right fa fa-check success"></span>';
            html += '</a>';
        }

        //If there's no html, set it to say "No assignments found"
        if(html == '') { html = '<p class="noRecordsFound">No assignments found.</p>'; }

        //Add the HTML to the course assignments panel
        $('#curCourseAssignments').html(html);
    });
}
*/

function toggleAssignment(assignmentID)
{
    //Retrieve the type of assignment
    var assignType = $('#assignment-' + assignmentID).attr('data-type');

    //Determine if this is selected/deselected
    var selected = $('#assignment-' + assignmentID).attr('data-selected');

    //Get the course id
    var courseID = $('#assignment-' + assignmentID).attr('data-courseid');

    //Get the course id
    var id = $('#assignment-' + assignmentID).attr('data-id');

    //Toggle the assignment value
    $.ajax({
        type: "POST",
        url: "course-library/update-assignment",
        data: { type: assignType, selected: selected, id:id, assignment_id: assignmentID, object_id:courseID, _token: $("input[name=_token]").val() },
        success: function(response) {

            //Show/hide the checkmark based on assignment type
            if(selected == 'true')
            {
                $('#assignment-' + assignmentID).attr('data-selected', 'false');
                $('#assignment-' + assignmentID + ' .fa-check').addClass('hide');
            }
            else
            {
                $('#assignment-' + assignmentID).attr('data-selected', 'true');
                $('#assignment-' + assignmentID + ' .fa-check').removeClass('hide');
                $('#assignment-' + assignmentID).attr('onClick', 'toggleAssignment(\'' + response + '\');');
                $('#assignment-' + assignmentID).attr('id', 'assignment-' + response);

            }

        },
        error: function(){

            var errorString = '';
            var errors = data.responseJSON.errors;

            for (var key in errors) {
                if (errors.hasOwnProperty(key)) {
                    errorString += errors[key] + " ";
                }
            }

            //Set the question warning text and display warning alert
            $('.alert-danger span').html(errorString);
            $(".alert-danger span").css('display', 'block');
        }
    });
}

function manageCourseAssignments(action)
{
    //Hide any alert messages
    $('.assignCourseSeriesAlert').addClass('hide');

    //Grab the currently selected courses and selected course series
    var courseIDs = $('#courseIDs').val();
    var seriesID = $("#assigned_series").val();

    //Confirm a series is selected
    if(seriesID == '')
    {
        $('#assignCouresSeriesError').removeClass('hide');
        $('#assignCourseSeriesErrorMsg').text('Please select a series before assigning courses.');
        return;
    }

    $.ajax({
        type: "POST",
        url: "course-library/save-assignments",
        data: { course_ids: courseIDs, series_id: seriesID, action: action, _token: $("input[name=_token]").val() },

        success: function(response) {

            $('#assignCourseSeriesAlert').removeClass('hide');
            $('#assignCourseSeriesAlertMsg').text(response.message);

            //Remove any existing assignments
            $('#courseIDs').val('');
            $('.courses').removeClass('selected');

            //Reset the dropdown menu and buttons
            $("#assigned_series").val('null');
            $('.selectpicker').selectpicker('refresh');

            $('#updateAssignmentBtn').addClass('hide');
            $('#removeAssignmentBtn').addClass('hide');
            $('#addAssignmentBtn').removeClass('hide');

        },
        error: function(){

        }
    });
}

function loadSeriesAssignments()
{
    //Hide any alert messages
    $('.assignSeriesAlert').addClass('hide');

    //Remove any existing assignments
    $('#courseIDs').val('');
    $('.courses').removeClass('selected');

    //Retrieve selected series
    var seriesID = $("#assigned_series").val();

    //If no series is selected simply return;
    if(seriesID == 'null') { return; }

    //Confirm a series is selected
    if(seriesID == '')
    {
        $('#assignSeriesAlert').removeClass('hide');
        $('#assignSeriesAlertMsg').text('Please select a series before assigning courses.');
        return;
    }

    //Retrieve the course assignments for this series
    var url = '/course-library/series-assignments/'+ seriesID;
    var courseIDs;

    $.get(url, function(response){

        //Grab the data from the response object
        var d = response.data;

        if(d.length > 0)
        {
            //Setup buttons to edit series assignments
            $('#updateAssignmentBtn').removeClass('hide');
            $('#removeAssignmentBtn').removeClass('hide');
            $('#addAssignmentBtn').addClass('hide');
        }
        else
        {
            //Setup buttons to add series assignments
            $('#updateAssignmentBtn').addClass('hide');
            $('#removeAssignmentBtn').addClass('hide');
            $('#addAssignmentBtn').removeClass('hide');
        }

        for(a=0; a<d.length; a++)
        {
            $('#recordID-' + d[a].course_id).addClass('selected');

            courseIDs = $('#courseIDs').val();
            courseIDs = courseIDs + courseIDs + ',' + d[a].course_id;

            $('#courseIDs').val(courseIDs);
        }

    });
}

function initAssignSeriesMenus(curLearners, curGroups)
{
    loadGroups('#client_id', '#series_group_id', curGroups);
    loadLearnersMenu('#client_id', '#series_group_id', '#series_learners', curLearners);

    //Setup Selectpicker Dropdown Menus
    $('#series_group_id').selectpicker({ liveSearch: true });
    $('#series_learners').selectpicker({ liveSearch: true });

    if(curLearners == '' || curLearners == undefined) { curLearners = null; }
    if(curGroups == '' || curGroups == undefined) { curGroups = null; }

    //Add onchange listener to assignment type dropdown menu
    $('#seriesAssignmentTypeGroup select').on('change', function(){

        var curType = $(this).val();

        //Hide/show the appropriate drop down menus based on the current type
        if(curType == 'group_id')
        {
            $('#seriesLearnersGroup').addClass('hide');
        }
        else
        {
            //Display the learners menu and populate based on the currently selected group
            $('#seriesLearnersGroup').removeClass('hide');
            loadLearnersMenu('#client_id', '#series_group_id', '#series_learners', curLearners);
        }
    });


    if($('#seriesAssignmentTypeGroup select').val() == 'group_id')
    {
        $('#seriesLearnersGroup').addClass('hide');
    }
    else
    {
        //Display the learners menu and populate based on the currently selected group
        $('#seriesLearnersGroup').removeClass('hide');
        loadLearnersMenu('#client_id', '#series_group_id', '#series_learners', curLearners);
    }

    //Add listener to update learners when a specific group is selected
    $('#seriesGroupIdGroup select').on('change', function(){

        loadLearnersMenu('#client_id', '#series_group_id', '#series_learners', curLearners);
    });


    //Add listener to the current series drop down menu in order to load the existing assignments
    $('#cur_series').on('change', loadCurSeriesAssignments);

}


function manageSeriesAssignments(action)
{
    //Hide any alert messages
    $('.assignSeriesAlert').addClass('hide');

    //Grab the currently selected series and assignment details
    var seriesID = $('#cur_series').val();
    var assignTo = $("#series_assignment_type").val();
    var groupIDs = $("#series_group_id").val();
    var learnerIDs = $("#series_learners").val();

    $.ajax({
        type: "POST",
        url: "course-library/assign-series",
        data: { assignment_type: assignTo, group_id: groupIDs, learners: learnerIDs, series_id: seriesID, action: action, _token: $("input[name=_token]").val() },

        success: function(response) {

            $('#assignSeriesAlert').removeClass('hide');
            $('#assignSeriesAlertMsg').text(response.message);

            //Reset the dropdown menu and buttons
            $("#cur_series").val('');
            $("#series_assignment_type").val('');
            $("#series_group_id").val('');
            $("#series_learners").val('');

            $('.selectpicker').selectpicker('refresh');

            $('#updateAssignmentBtn').addClass('hide');
            $('#removeAssignmentBtn').addClass('hide');
            $('#addAssignmentBtn').removeClass('hide');

        },
        error: function(response){

            var errorString = '';
            var errors = response.responseJSON.errors;

            for (var key in errors) {
                if (errors.hasOwnProperty(key)) {
                    errorString += errors[key] + " ";
                }
            }

            $('#assignSeriesError').removeClass('hide');
            $('#assignSeriesErrorMsg').text(errorString);

        }
    });
}


function loadCurSeriesAssignments()
{
    //Grab the current series id
    var seriesID = $('#cur_series').val();

    //Build URL to grab assignments
    var url = '/course-library/assign-series/' + seriesID;

    $.get(url, function(response){

        var groups = response.groups;
        var learners = response.learners;

        var groupsArray = new Array();
        var learnersArray = new Array();


        //Loop through each group  in the menu and if it exists in the groups array returned, select it
        for(a=0; a<groups.length; a++) {  groupsArray[a] = groups[a].group_id; }

        //Loop through each learner in the menu and if it exists in the learners array returned, select it
        for(a=0; a<learners.length; a++) { learnersArray[a] = learners[a].user_id;  }

        //Set the groups prior to triggering the assignment series menu change
        $('#series_group_id').selectpicker('val', groupsArray);

        //Set the assignment dropdown
        $('#series_assignment_type').selectpicker('val', response.series.assignment_type);

        //Trigger a change event on the assignment type. This change will trigger loadLeanersMenu to repopulate the learners menu
        $('#series_assignment_type').trigger('change');

        //Select the learners, pausing for the menu to be rebuilt after the assignment type change was triggered
        setTimeout(function(){ $('#series_learners').selectpicker('val', learnersArray); }, 750);
    });
}
