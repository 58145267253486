/*------------------------------------------------------------------------
 # author    Eric Worman, HotRocket Development, Inc.
 # copyright Copyright  2014. All rights reserved.
 # @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
 # Website   eTraining LMS
 # Path: /resources/assets/js/learner.js
 -------------------------------------------------------------------------*/



/* Quiz Functions --------------------------------------------------------------------------------------------------------- */

function startQuiz(quizID, confirm)
{
    if(confirm == true)
    {
        $('#confirmStartPanel').modal();
        return;
    }
    else
    {
        var url = "training/start-quiz/" + quizID;

        $.get(url, function(response){

            //Enable the form
            $('input:radio').prop('disabled', false);
            $('#finishBtn').prop('disabled', false).removeClass('disabled');
            $('#quizContainer').removeClass('disabledForm');

            //Delete the start button
            $('#startBtn').remove();
        });
    }
}

function finishQuiz(quizID)
{
    var curName = null;
    var isAnswered = false;

    //Remove any field error classes
    $('.questionWrapper').removeClass('fieldError');

    //Hide previous error alert
    $('#questionAlertWarning').css('display', 'none');

    $('.questionWrapper').each(function(){

        //Retrieve radio buttons
        $(this).children('.answerBlock').children('.radio').children('input').each(function(){

            if($(this).prop('checked') == true) { isAnswered = true; }
        });

        //If the question has not been answered, add the field error class to the LI tag
        if(isAnswered == false) { $(this).addClass('fieldError'); }

        //reset isAnswered var
        isAnswered = false;
    });

    //Check for any field error classes on the question wrapper and display alert if found
    if($('.questionWrapper').hasClass('fieldError'))
    {
        $('#questionAlertWarning').css('display', 'block');
    }
    else
    {
        //Mark test as finished
        var url = 'training/finish-quiz/' + quizID;

        $.get(url, function(response){

            //Enable the form
            $('input:radio').prop('disabled', true);
            $('#finishBtn').prop('disabled', true).addClass('disabled');
            $('#quizCompleteAlert').css('display', 'block');
            scrollTo(0,0);
        });

    }
}

function saveAnswer(curInput)
{
    //Retrieve required variables
    var data = {
        quiz_id: $('#quizID').val(),
        question_id: $(curInput).attr('data-question'),
        answer: $(curInput).attr('value'),
        text: $(curInput).attr('data-text'),
        _token: $("input[name=_token]").val()
    }

    $.ajax({
        type: "POST",
        url: "training/save-answer",
        data: data,
        success: function(response) {

        },
        error: function(response){

        }
    });
}




/* Course Functions --------------------------------------------------------------------------------------------------------- */

function showCourseDetails(courseID)
{
    //Retrieve course data and populate main course details panel
    var url = 'training/course-details/' + courseID;

    $.get(url, function(response){

        var course = response.course;
        var stats = response.stats;

        //Set the course based on the return data
        var courseTitle = course.title;
        var description = course.description;
        var duration = course.duration;
        var expires = course.expires;
        var image = course.image;
        var syllabus = course.syllabus;
        var preview = course.preview;

        $('#courseTitle').html(courseTitle);
        $('#description').html(description);

        if(duration == '')
        {
            $('#duration').css('display', 'none');
        }
        else
        {
            $('#duration').html('Duration: ' + duration);
            $('#duration').css('display', 'inline');
        }


        $('#expires').html(expires);

        //Set the course preview image
        if(image == null|| image == '')
        {
            $('#coursePreviewImage').attr('src', 'images/course-placeholder.jpg');
        }
        else
        {
            $('#coursePreviewImage').attr('src', 'download/course/image/' + courseID);
        }

        var buttonsHTML = '';

        //If a syllabus has been added, add the button to view it as well
        if(syllabus != null)
        {
            buttonsHTML += '<a class="btn btn-default btn-sm" href="/download/course/syllabus/' + courseID + '">Syllabus</a>';
        }

        //Add preview if one exists
        if(preview != null)
        {
            buttonsHTML += '<a target="_blank" class="btn btn-default btn-sm" href="/download/course/preview/' + courseID + '">Preview</a>';
        }

        //If stats is null, init the lesson status var to null as well
        if(stats == null)
        {
            stats = new Object();
            stats.lesson_status = null;
        }

        //If the status of the course is incomplete, display a 'resume test' or 'start over' button. Start over will remove suspend data for this stat entry
        if(stats.lesson_status == 'incomplete')
        {
            buttonsHTML += "<a id='resumeTest' onclick=\"$.colorbox({href: '/learner-courses/test/" + courseID + "', width:'798px', height:'680px', iframe:true });\" href=\"javascript:\" class=\"btn btn-default btn-sm\">";
            buttonsHTML += '<span class="glyphicon glyphicon-facetime-video"></span> Resume Course</a>';

            buttonsHTML += "<a id='startOver' onclick=\"purgeSuspendData('" + stats.id + "', '" + courseID + "');\" href=\"javascript:\" class=\"btn btn-default btn-sm\">";
            buttonsHTML += '<span class="glyphicon glyphicon-refresh"></span> Start Over</a>';
        }
        else
        {
            buttonsHTML += "<a id='testButton' onclick=\"$.colorbox({href: '/learner-courses/test/" + courseID + "', width:'798px', height:'680px', iframe:true });\" href=\"javascript:\" class=\"btn btn-default btn-sm\">";
            buttonsHTML += '<span class="glyphicon glyphicon-facetime-video"></span> Take Course</a>';
        }

        //Update buttons
        $('#resourceDetails .courseButtons').html(buttonsHTML);

        //Check the global testing disabled var and disable the test button if testing is disabled
        if(testingEnabled == 'false') { $('#testButton').attr('onclick', '').addClass('disabled'); }

        //Scroll back to the top of the page
        window.scrollTo(0,0);


    })
}

function purgeSuspendData(statID, courseID)
{
    //Purge the suspend data for the course and launch
    var url = 'training/purge-suspend-data/' + statID;

    $.get(url, function(response){

        //Launch the course
        $.colorbox({href: '/learner-courses/test/' + courseID, width:'798px', height:'680px', iframe:true });
    });
}


function getPassFail()
{
    //course window
    var cw = window.frames[0];

    console.log(cw.cpAPIInterface.getVariableValue('cpQuizInfoPassFail'));

    //Grab the total number of slides
    var pass = cw.cpAPIInterface.getVariableValue('cpQuizInfoPassFail');

    //Format pass boolean into an integer for insertion into the DB
    if(pass == true) { return 1; } else { return 0; }
}

function finishCourse(dataCache, API)
{

    //Don't update the stats when testing
    if(API.testMode == 1) { return; }

    var url = '/course-stats/update-stats';
    var data = {
        course_id: API.courseID,
        user_id: API.userID,
        stat_id: API.stat_id,
        pass: API.pass,
        scorm_data: dataCache,
        _token:$('input[name=_token]').val()
    };

    $.ajax({
        type: "POST",
        url: url,
        data: data,
        success: function(response) {

            //Reset the data cache object
            dataCache.update = false;
            dataCache = null; //Clear out the data cache

        },
        error: function(response){

            //Log the error that was generated
            console.log(response.error);
        }
    });
}

function completeCourse(CPData)
{
    console.log(CPData);
}



/* Certificate Functions --------------------------------------------------------------------------------------------------------- */

function downloadCertificate(statID)
{
    //Set iframe target download URL
    $("#certificateDownload").attr('src', '/training/download-certificate/' + statID);
}



/* Purchase learner credits --------------------------------------------------------------------------------------------------------- */

function calculateCreditCost(numCredits)
{
    //Create URL to calculate the costs based on the number selected
    var url = '/account/credit-cost/' + numCredits;

    $.get(url, function(response){

        console.log(response);
        
        var totalCost = parseFloat(response).toFixed(2);

       $('#totalPrice').val(totalCost);
    });
}

function calculateLicenseCost(numLicenses)
{
    //Create URL to calculate the costs based on the number selected
    var url = '/account/license-cost/' + numLicenses;

    $.get(url, function(response){

        var prorated = parseFloat(response.prorated).toFixed(2);
        var total = parseFloat(response.total).toFixed(2);

        $('#prorated').val(prorated);
        $('#total').html(total);
    });
}

function processStripePayment(purchaseType)
{
    //Grab the details to pass to the server for processing
    var data = {
        stripeToken: $('input[name=stripeToken]').val(),
        _token:  $('input[name=_token]').val(),
        name: $('#name').val(),
        email: $('#email').val(),
        receipt_email: $('#email').val(),
        quantity: $('#quantity').val(),
        purchase_type: purchaseType
    };

    var url = '/account/make-payment';

    $.ajax({
        type: "POST",
        url: url,
        data: data,
        success: function(response) {

            $('#paymentSuccess').css('display', 'block');
            $('#payFeeBtn').prop('disabled', false).html('Purchase Credits');
            $('#payment-form input').val('');
            $('#paymentMsg').html(response.message);
            $("#totalUnits").html(response.total);

            //Set the button text based on purchase type
            if(purchaseType == 'credit')
            {
                $('#payFeeBtn').prop('disabled', false).html('Purchase Credits');
            }
            else
            {
                $('#payFeeBtn').prop('disabled', false).html('Purchase Licenses');
            }

        },
        error: function(response){

            var errors = response.responseJSON.errors;

            //Remove any existing results from the table
            $('.searchResult').remove();

            for (var key in errors) {
                if (errors.hasOwnProperty(key)) {

                    console.log(key + ": " + errors[key]);

                }
            }
        }
    });

}