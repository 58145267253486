/*------------------------------------------------------------------------
 # author    Eric Worman, HotRocket Development, Inc.
 # copyright Copyright  2014. All rights reserved.
 # @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
 # Website   ETraining LMS
 # Path: /resources/assets/js/graphs.js
 -------------------------------------------------------------------------*/

function adminGraphs(config)
{
    //Init base fee vars
    this.creditsWeek = null;
    this.creditsMonth = null;
    this.creditsYear = null;

    this.licensesWeek = null;
    this.licensesMonth = null;
    this.licensesYear = null;

    this.token = null;

    //Set the initial fee data points if passed
    if(config.curFees != undefined)
    {
        this.creditsWeek = config.curFees.creditsWeek;
        this.creditsMonth = config.curFees.creditsMonth;
        this.creditsYear = config.curFees.creditsYear;

        this.licensesWeek = config.curFees.licensesWeek;
        this.licensesMonth = config.curFees.licensesMonth;
        this.licensesYear = config.curFees.licensesYear;

        this.token = config.token;
    }


    //Define the options for each chart type
    this.xaxisWeek = { mode: "time", tickSize: [1, "day"], timeformat: '%m/%d', tickLength:0 };
    this.xaxisMonth = { mode: "time", tickSize: [1, "day"], timeformat: '%d', tickLength:0 };
    this.xaxisYear = { mode: "time", tickSize: [1, "month"], timeformat: '%m/%y', tickLength:0 };


    //Define common options for all charts
    this.chartSeries = {
        lines: {
            show: true,
            fill: true,
            fill: 0.1,
            lineWidth: 2
        },
        points: {
            show: true,
            radius: 5,
            fill: true,
            fillColor: "#ffffff",
            lineWidth: 2,
        }
    };

    this.chartGrid = {
        hoverable: true,
        clickable: false,
        borderWidth: 0,
        tickColor: "#eee",
        borderColor: "#ccc",
        labelMargin: 15
    };

    this.chartLegend = {
        show: false,
        position: 'ne'
    };

    this.chartTooltipOpts = {
        content: function (label, xval, yval, flotItem) { return label + ': ' + yval.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",") },
        defaultTheme: false
    };

    this.chartColors = ['#058DC7', '#666666', '#333333', '#CCCCCC'];


    //Setup chart options for each chart
    this.chartOptionsWeek = { xaxis: this.xaxisWeek, yaxis: { },  series: this.chartSeries, grid: this.chartGrid, legend: this.chartLegend, tooltip: true, tooltipOpts: this.chartTooltipOpts ,  shadowSize: 0, colors: this.chartColors };
    this.chartOptionsMonth = { xaxis: this.xaxisMonth, yaxis: { },  series: this.chartSeries, grid: this.chartGrid, legend: this.chartLegend, tooltip: true, tooltipOpts: this.chartTooltipOpts ,  shadowSize: 0, colors: this.chartColors };
    this.chartOptionsYear = { xaxis: this.xaxisYear, yaxis: { },  series: this.chartSeries, grid: this.chartGrid, legend: this.chartLegend, tooltip: true, tooltipOpts: this.chartTooltipOpts ,  shadowSize: 0, colors: this.chartColors };


    this.renderGraphs = function(curGraph)
    {
        //Based on the current graph type, set the fees accordingly
        switch(curGraph)
        {
            case "#creditsWeek":
                this.curData = [{ label: 'Amount',  data: this.creditsWeek }];
                this.chartOptions = this.chartOptionsWeek;
                break;
            case "#creditsMonth":
                this.curData = [{ label: 'Amount',  data: this.creditsMonth }];
                this.chartOptions = this.chartOptionsMonth;
                break;
            case "#creditsYear":
                this.curData = [{ label: 'Amount',  data: this.creditsYear }];
                this.chartOptions = this.chartOptionsYear;
                break;
            case "#licensesWeek":
                this.curData = [{ label: 'Amount',  data: this.licensesWeek }];
                this.chartOptions = this.chartOptionsWeek;
                break;
            case "#licensesMonth":
                this.curData = [{ label: 'Amount',  data: this.licensesMonth }];
                this.chartOptions = this.chartOptionsMonth;
                break;
            case "#licensesYear":
                this.curData = [{ label: 'Amount',  data: this.licensesYear }];
                this.chartOptions = this.chartOptionsYear;
                break;
        }

        $.plot(curGraph, this.curData, this.chartOptions);
    };

    this.selectMonth = function()
    {
        var _this = this;

        //Setup post data var
        var data = {
            credit_date: $('#creditDate').val(),
            license_date: $('#licenseDate').val(),
            _token:  $('input[name=_token]').val()
        };

        var url = 'dashboard/update-graphs';

        $.ajax({
            type: "POST",
            url: url,
            data: data,
            success: function(response) {

                //Rebuild the fees objects withthe new data
                _this.populateFeeObjects(response);

                //Re-render the graphs based on active tabs
                _this.getActiveTabs();

            },
            error: function(response){

                var errors = response.responseJSON.errors;

                for (var key in errors) {
                    if (errors.hasOwnProperty(key)) {

                        console.log(key + ": " + errors[key]);

                    }
                }
            }
        });

    }

    this.populateFeeObjects = function(feeData)
    {
        //Rebuild the creditsWeek array
        var creditsWeek = new Array();
        for(a=0; a<feeData.credits.week.length; a++)
        {
            creditsWeek[a] = new Array((feeData.credits.week[a].date * 1000), parseFloat(feeData.credits.week[a].cost));
        }
        this.creditsWeek = creditsWeek;

        //Rebuild the creditsMonth array
        var creditsMonth = new Array();
        for(a=0; a<feeData.credits.month.length; a++)
        {
            creditsMonth[a] = new Array((feeData.credits.month[a].date * 1000), parseFloat(feeData.credits.month[a].cost));
        }
        this.creditsMonth = creditsMonth;

        //Rebuild the creditsYear array
        var creditsYear = new Array();
        for(a=0; a<feeData.credits.year.length; a++)
        {
            creditsYear[a] = new Array((feeData.credits.year[a].date * 1000), parseFloat(feeData.credits.year[a].cost));
        }
        this.creditsYear = creditsYear;




        //Rebuild the licensesWeek array
        var licensesWeek = new Array();
        for(a=0; a<feeData.licenses.week.length; a++)
        {
            licensesWeek[a] = new Array((feeData.licenses.week[a].date * 1000), parseFloat(feeData.licenses.week[a].cost));
        }
        this.licensesWeek = licensesWeek;


        //Rebuild the adminFeesMonth array
        var licensesMonth = new Array();
        for(a=0; a<feeData.licenses.month.length; a++)
        {
            licensesMonth[a] = new Array((feeData.licenses.month[a].date * 1000), parseFloat(feeData.licenses.month[a].cost));
        }
        this.licensesMonth = licensesMonth;


        //Rebuild the adminFeesYear array
        var licensesYear = new Array();
        for(a=0; a<feeData.licenses.year.length; a++)
        {
            licensesYear[a] = new Array((feeData.licenses.year[a].date * 1000), parseFloat(feeData.licenses.year[a].cost));
        }
        this.licensesYear = licensesYear;
        

    };

    this.getActiveTabs = function()
    {
        //Get the active app fee tab and render the graph
        var creditsTab = $('#creditsTabs .tab-pane.active').attr('id');
        this.renderGraphs('#' + creditsTab);

        var licensesTab = $('#licensesTabs .tab-pane.active').attr('id');
        this.renderGraphs('#' + licensesTab);

    }

}