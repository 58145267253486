/*------------------------------------------------------------------------
 # author    Eric Worman, HotRocket Development, Inc.
 # copyright Copyright  2014. All rights reserved.
 # @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
 # Website   eTraining LMS
 # Path: /resources/assets/js/main.js
 -------------------------------------------------------------------------*/
//jQuery document initialization
//$(document).ready(function() {  });


//jQuery window resize listener
$(window).resize(function() {

    //Admin function to reformat the field labels for either 2 lines high or one
    formatFieldLabels();
});


//jQuery Scroll Listener
//$(window).scroll(function() { });


//Add all site initialize here
function initPage()
{

}

//This function retrieves all elements with the formLabel class and if the label exceeds the default height, add the correct formatting class
function formatFieldLabels()
{

    $('.formLabel').each(function(){
        var h = $(this).height();
        if (h > 30) { $(this).addClass('twoLineLabel'); } else { $(this).removeClass('twoLineLabel'); }
    })
}

function deleteRecord(url, confirmDelete)
{
    if(confirmDelete == true)
    {
        $('#confirmDeletePanel').modal();
        return;
    }

    window.location.href=url;
}

function removeClientImage(imageType, clientID, confirm)
{
    var curImageType = imageType; //Store the function var in a local var to make available to the $.get block

    //If this removal has not yet been confirmed, update the remove image button onclick handler and display the panel
    if(confirm == true)
    {
        $('#removeImageButton').unbind(); //Unbind any previously attached click handlers
        $('#removeImageButton').click(function(){ removeClientImage(imageType, clientID, false); });
        $('#confirmDeleteImagePanel').modal();

        return;
    }

    var url = 'client-accounts/remove-image/' + imageType + "/" + clientID;
    $.get(url, function(response){

        //Update the image controls to reflect successful removal of image
        $('#' + curImageType + '.removeImageControls').html('Image successfully removed.');

        //Apply the success class to the controls container
        $('#' + curImageType + '.removeImageControls').addClass('success');
    });
}


function removeLearnerImage(learnerID, confirm)
{
    //If this removal has not yet been confirmed, update the remove image button onclick handler and display the panel
    if(confirm == true)
    {
        $('#removeImageButton').unbind(); //Unbind any previously attached click handlers
        $('#removeImageButton').click(function(){ removeLearnerImage(learnerID, false); });
        $('#confirmDeleteImagePanel').modal();

        return;
    }

    var url = 'learners/remove-image/' + learnerID;

    $.get(url, function(response){

        //Update the image controls to reflect successful removal of image
        $('#image.removeImageControls').html('Image successfully removed.');

        //Apply the success class to the controls container
        $('#image.removeImageControls').addClass('success');
    });
}

function removeFile(url, target, confirm)
{
    //If this removal has not yet been confirmed, update the remove image button onclick handler and display the panel
    if(confirm == true)
    {
        $('#removeFileButton').unbind(); //Unbind any previously attached click handlers
        $('#removeFileButton').click(function(){ removeFile(url, target, false); });
        $('#confirmDeleteFilePanel').modal();

        return;
    }

    $.get(url, function(response){

        //Update the image controls to reflect successful removal of image
        $(target).html('File successfully removed.');

        //Apply the success class to the controls container
        $('.removeImageControls').addClass('success');
    });
}