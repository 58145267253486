/*------------------------------------------------------------------------
 # author    Eric Worman, HotRocket Development, Inc.
 # copyright Copyright  2014. All rights reserved.
 # @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
 # Website   eTraining LMS
 # Path: /resources/assets/js/SCORM_api.js
 # NOTE: This file is designed to be included at the bottom of the test
 #       loading page.
 -------------------------------------------------------------------------*/

var API = {};
var cmi = {};

var dataCache = {};


function initSCORM(coursePath, target, courseID, userID, testMode) {
    API.LMSInitialize = LMSInitialize;
    API.LMSGetValue = LMSGetValue;
    API.LMSSetValue = LMSSetValue;
    API.LMSCommit = LMSCommit;
    API.LMSFinish = LMSFinish;
    API.LMSGetLastError = LMSGetLastError;
    API.LMSGetDiagnostic = LMSGetDiagnostic;
    API.LMSGetErrorString = LMSGetErrorString;

    API.courseID = courseID;
    API.userID = userID;
    API.coursePath = coursePath;
    API.target = target;
    API.testMode = testMode;

    //Init the data cache update var to false and begin the updater
    dataCache.update = false;
    dataCache.interval = setInterval(updateStats, 1000); //Update the stats in the database every second if necessary

    preloadCMIData();
}

function LMSInitialize(initializeInput) {

    //displayLog("LMSInitialize: " + initializeInput);

    return true;
}

function LMSGetValue(varname) {

    //displayLog("LMSGetValue: " + varname);

    //console.log(cmi.server_response[varname]);

    //Retrieve the value from the cmi object initialized at startup
    //return cmi.server_response[varname];

    return "";
}

function LMSSetValue(varname, varvalue) {

    //displayLog("LMSSetValue: " + varname + "=" + varvalue);

    //Set the cmi value
    cmi.varname = varvalue;

    //Set the process var, only update course stats for certain variables
    var process = false;

    var scormVar = varname.split(/[\.]+/).pop();

    //Switch out the scorm var and determine if we should update the course stats with this value
    switch(scormVar)
    {
        case "suspend_data":
        case "lesson_status":
        case "max":
        case "raw":
        case "id":
        case "time":
            process = true;
            break;
        default:
            process = false;
    }

    //If process is set to true, update the data cache or next
    if(process == true)
    {
        dataCache.update = true;
        dataCache[scormVar] = varvalue;
    }

    if(scormVar == 'lesson_location')
    {
        //console.log(API.lastSlide + ' - ' + varvalue);

        //Check if this is the last slide
        if(API.lastSlide == varvalue)
        {
            //Determine whether this quiz passed or failed and update stats accordingly
            var results = getPassFail();
            API.pass = results;
            dataCache['lesson_status'] = 'completed';

            console.dir(API);

            //Close the colorbox
           window.parent.parent.parent.$.colorbox.close();
        }
    }

    return "";
}
function LMSCommit(commitInput) {

   //displayLog("LMSCommit: " + commitInput);

    //updateStats();

    return true;
}
function LMSFinish(finishInput) {

    //displayLog("LMSFinish: " + finishInput);
    window.top.finishCourse(dataCache, API);

    return true;
}
function LMSGetLastError(errorCode) {
    //displayLog("LMSGetLastError: " + errorCode);
    return 0;
}
function LMSGetDiagnostic(errorCode) {
    //displayLog("LMSGetDiagnostic: " + errorCode);
    return "";
}
function LMSGetErrorString(errorCode) {
    //displayLog("LMSGetErrorString: " + errorCode);
    return "";
}
function displayLog(textToDisplay){
    /*
     var loggerWindow = document.getElementById("logDisplay");
     var item = document.createElement("div");
     item.innerText = textToDisplay;
     loggerWindow.appendChild(item);
     */
    console.log(textToDisplay);
}


function preloadCMIData()
{
    console.log('Preloading CMI Data');

    //Retrieve cmi data required for LMS initialization
    var url = '/course-stats/preload-data';
    var data = { course_id: API.courseID, user_id: API.userID, _token:$('input[name=_token]').val() };

    $.ajax({
        type: "POST",
        url: url,
        data: data,
        success: function(response) {

            //Append the response into the cmi object for retrieval
            cmi.server_response = response.data;
            API.stat_id = response.data.stat_id;

            //Launch the course
            window.open(API.coursePath, API.target, "resizable,scrollbars,status");

        },
        error: function(response){

            //Log the error that was generated
            console.log(response.error);
        }
    });
}


function updateStats()
{
    //If there's no data to update, return
    if(dataCache.update == false || API.testMode == '1') { return; }

    var url = '/course-stats/update-stats';
    var data = {
        course_id: API.courseID,
        user_id: API.userID,
        stat_id: API.stat_id,
        scorm_data: dataCache,
        _token:$('input[name=_token]').val()
    };

    $.ajax({
        type: "POST",
        url: url,
        data: data,
        success: function(response) {

         //Reset the data cache object
         dataCache.update = false;
         API.stat_id = response.stat_id;

        },
        error: function(response){

            //Log the error that was generated
            console.log(response.error);
        }
    });
}